import type { IAccidentDetailService } from "~/components/global/accident-detail/interfaces/IAccidentDetailService";
import type { IAccident } from "~/components/global/accident-detail/interfaces/IAccident";
import type { RouteParamValue } from "vue-router";
import { api } from "~/api/api";
import type { TFetchError } from "@/assets/ts/types/TFetchError";
import type { AsyncData } from "#app";
import type { IAccidentDetailState } from "~/components/global/accident-detail/interfaces/IAccidentDetailState";
import type { TAccidentApplicationDetailFetchData } from "~/components/global/accident-detail/types/TAccidentApplicationDetailFetchData";
import { ApplicationState } from "~/components/global/application/models/ApplicationState";
import type { TApplicationState } from "~/components/global/application/types/TApplicationState";

export class AccidentApplicationDetailService implements IAccidentDetailService<TAccidentApplicationDetailFetchData> {
	constructor(
		private state: IAccident<IAccidentDetailState>,
		private accidentGuid: string | RouteParamValue[],
	) {}

	get getAccidentGuid(): string | RouteParamValue[] {
		return this.accidentGuid;
	}

	requestToAccidentDetail(): AsyncData<TAccidentApplicationDetailFetchData | null, TFetchError> {
		return $useFetch<TAccidentApplicationDetailFetchData, TFetchError>(`${api.applications}repair/${this.accidentGuid}/`, {
			method: "GET",
		});
	}

	async generateDetailData(): Promise<void> {
		const { data } = await this.requestToAccidentDetail();

		if (!data.value) return;

		const ExApplicationState = new ApplicationState(
			ref<TApplicationState>({
				attributes: {
					answer: data.value.data.attributes.answer || "",
					answer_date: data.value.data.attributes.date_answer || "",
					date: data.value.data.attributes.datetime || "",
					reason: data.value.data.attributes.text || "",
					file_links: data.value.data.attributes.file_links,
				},
				type: "",
				uuid: "",
			}),
		);

		this.state.updateState({
			type: data.value.data.type,
			accident_info: {
				author: data.value.data.attributes.user.attributes.name,
				auto: data.value.data.attributes.auto.attributes.model,
				driver: data.value.data.attributes.driver?.attributes.name,
				uuid_accident: data.value.data.attributes.accident,
			},
			number: data.value.data.attributes.number,
			projection: data.value.data.attributes.projection,
			odometer: data.value.data.attributes.odometer ? `${data.value.data.attributes.odometer}` : null,
			status: data.value.data.attributes.status,
			application: ExApplicationState.state,
		});
	}
}
