<template>
	<div class="layout-default container layout-error">
		<AsideMenu />

		<Card class="layout-error__card p-4">
			<template #content>
				<div class="layout-error__content">
					<div class="layout-error__picture">
						<img
							class="layout-error__image"
							src="@/assets/images/car.png"
							alt="Error" />
					</div>

					<div class="flex flex-column align-items-start justify-content-center">
						<p class="layout-error__code text-red-500 mb-4">{{ error.statusCode }}</p>

						<p class="layout-error__message text-4xl font-semibold mb-4">
							{{ errorMessage }}
						</p>

						<p class="layout-error__description text-xl mb-5">
							{{ errorDescription }}
						</p>

						<div class="layout-error__controls flex gap-3">
							<NuxtLink
								v-if="error.statusCode === 500"
								:to="error.url"
								external>
								<Button
									label="Обновить страницу"
									icon="pi pi-refresh"
									cseverity="danger"
									outlined />
							</NuxtLink>

							<NuxtLink :to="Routes.Profile">
								<Button
									label="Мой профиль"
									class="p-button-danger" />
							</NuxtLink>
						</div>
					</div>
				</div>
			</template>
		</Card>
	</div>
</template>

<script setup>
	import { Routes } from "@/assets/ts/enums/Routes.ts";

	const props = defineProps({
		error: {
			type: Object,
			required: true,
		},
	});

	const errorMessage = computed(() => (props.error.statusCode === 404 ? "К сожалению, такая страница не найдена" : props.error.statusCode === 500 ? "Внутренняя ошибка сервера" : ""));

	const errorDescription = computed(() =>
		props.error.statusCode === 404
			? "Страница, на которую вы пытаетесь перейти, не существует или была удалена."
			: props.error.statusCode === 500
				? "На сервере произошла непредвиденная ошибка. Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время"
				: "",
	);

	console.error(props.error);
</script>

<style scoped lang="scss">
	@import "@/assets/scss/layouts/default";
	@import "@/assets/scss/layouts/error";
</style>
