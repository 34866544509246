import type { IAccidentDetailService } from "~/components/global/accident-detail/interfaces/IAccidentDetailService";
import type { IAccident } from "~/components/global/accident-detail/interfaces/IAccident";
import type { RouteParamValue } from "vue-router";
import { api } from "~/api/api";
import type { TAccidentDetailFetchData } from "~/components/global/accident-detail/types/TAccidentDetailFetchData";
import type { TFetchError } from "@/assets/ts/types/TFetchError";
import type { AsyncData } from "#app";
import type { IAccidentAddApplicationDetailState } from "~/components/global/accident-detail/interfaces/IAccidentAddApplicationDetailState";

export class AccidentAddApplicationDetailService implements IAccidentDetailService<TAccidentDetailFetchData> {
	constructor(
		private state: IAccident<IAccidentAddApplicationDetailState>,
		private accidentGuid: string | RouteParamValue[],
	) {}

	get getAccidentGuid(): string | RouteParamValue[] {
		return this.accidentGuid;
	}

	requestToAccidentDetail(): AsyncData<TAccidentDetailFetchData | null, TFetchError> {
		return $useFetch<TAccidentDetailFetchData, TFetchError>(api.getCarAccident(this.getAccidentGuid), {
			method: "GET",
		});
	}

	async generateDetailData(): Promise<void> {
		const { data } = await this.requestToAccidentDetail();

		if (!data.value) return;

		this.state.updateState({
			accident_info: {
				auto_uuid: data.value.data.attributes.auto.uuid,
			},
		});
	}
}
