<script setup>
	import { ref } from "vue";
	import { api } from "~/api/api";
	import { $useUser } from "~/composables/useUser";
	import { $useFetch } from "~/composables/useFetch.ts";
	import { Routes } from "@/assets/ts/enums/Routes";
	import { getNumberNotification } from "@/assets/ts/functions/global/get-number-notification.ts";
	import { useUserNotifications } from "~/store/userNotifications.js";

	const route = useRoute();
	const { status, signOut } = useAuth();
	const config = useRuntimeConfig();
	const isAuthorized = computed(() => status.value === "authenticated");

	const logOut = async () => {
		const { data: logout } = await $useFetch(api.logout, {
			method: "POST",
			headers: {},
			body: {
				token: $useUser().refreshToken,
				client_id: config.CLIENT_ID,
				client_secret: config.FRONT_AUTH_SECRET,
			},
			transform: (mutations) => JSON.parse(mutations).revoked,
		});

		if (logout.value) await signOut();
	};

	const userNotificationsStory = useUserNotifications();
	const { getNumberUnread } = storeToRefs(userNotificationsStory);
	const { setNotification } = userNotificationsStory;

	const { data: dataNumberNotification } = await getNumberNotification();

	setNotification(dataNumberNotification.value);

	const activeRoute = (currentPath) => route.path.includes(currentPath);

	const items = ref([
		{
			separator: true,
		},
		{
			label: "Статус по машинам и водителям",
			icon: "pi pi-id-card",
			route: Routes.StatusCar,
		},
		{
			label: "Памятки и инструкции",
			icon: "pi pi-book",
			route: Routes.Instructions,
		},
		{
			label: "ДТП",
			icon: "pi pi-car",
			route: Routes.AccidentsList,
		},
		{
			label: "Список заявок",
			icon: "pi pi-list",
			route: Routes.ApplicationsList,
		},
		{
			label: "Полис автострахования",
			icon: "pi pi-shield",
			route: Routes.InsurancePolicy,
		},
		{
			label: "Штрафы",
			icon: "pi pi-exclamation-circle",
			route: Routes.Penalties,
		},
		{
			label: "Платные дороги",
			icon: "pi pi-credit-card",
			route: Routes.TollRoads,
		},
		{
			label: "Подменные автомобили",
			icon: "pi pi-arrow-right-arrow-left",
			route: Routes.ReplacementCars,
		},
		{
			separator: true,
		},
		{
			label: "Мой профиль",
			icon: "pi pi-user",
			route: Routes.Profile,
		},
		{
			label: "Уведомления",
			icon: "pi pi-bell",
			route: "/notifications",
			number: getNumberUnread,
		},
	]);
</script>

<template>
	<Menu
		class="menu"
		:model="items">
		<template #start>
			<img
				class="menu__logo"
				src="@/assets/images/logo.png"
				alt="Логотип" />
		</template>

		<template #item="{ item, props }">
			<nuxt-link
				v-if="item.route"
				v-ripple
				:to="item.route"
				v-bind="props.action"
				:class="[{ 'custom-router-link-active': activeRoute(item.route) }]">
				<span :class="[item.icon, 'p-menuitem-icon']" />

				<span>{{ item.label }}</span>

				<Badge
					v-if="item.number"
					class="menu__notify-counter notify-counter"
					:value="item.number" />
			</nuxt-link>
		</template>

		<template #end>
			<Button
				v-if="isAuthorized"
				class="menu__button"
				label="Выйти"
				icon="pi pi-power-off"
				@click="logOut" />
		</template>
	</Menu>
</template>
